<template>
  <div class="Layout__wrapper">
    <Navbar />
    <MobileNavbar />
    <b-container class="Layout__container mb-3">
      <!-- <StoreSwitcher /> -->
      <slot />
    </b-container>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import MobileNavbar from '@/components/MobileNavbar'
import Footer from '@/components/Footer'

export default {
  name: 'Layout',
  components: {
    // StoreSwitcher,
    Navbar,
    MobileNavbar,
    Footer
  },
  created() {
    this.fetchTime()
  },
  methods: {
    fetchTime() {
      this.$store.dispatch('serverTime/fetchServerTime')
    }
  }
}
</script>

<style lang="scss" scoped>
.Layout {
  // &__wrapper {
  //   padding-top: 40px;
  // }

  &__container {
    min-height: calc(100vh - 2rem - 33px - 56px);
  }
}
</style>
